(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

jQuery(document).ready(function ($) {

	$('.bxslider').bxSlider({
		mode: 'fade',
		auto: true,
		controls: false,
		pagerCustom: '#bx-pager'
	});

	var windowsize = $(window).width();
	if (windowsize > 767) {
		$('#menu-main-menu > li  li  a').closest('li').append("<div class='menu_preview'></div>");
		$('#menu-main-menu > li').hover(function () {
			var id = $(this).attr('id');
			var dataObj = $('[data-menu-item-id="' + id + '"]');
			$('.menu_preview').html(dataObj.html());
		});
	}

	$(".album_wrap").hover(function () {

		$(this).find('.album_hover').fadeToggle();
		$(this).find('.album_hover_detailed').fadeToggle();
	});

	$(".album_image_detailed").hover(function () {

		$(this).find('.album_hover_detailed').fadeToggle();
	});

	// validate the comment form when it is submitted

	$("#event-registration").validate();
});

},{}]},{},[1]);
